import React, { useState } from 'react';
import Nav from '../Nav';
import HeaderJob from './HeaderJob';
import Search from './Search';
import AvailableJobs from './AvailableJobs';
import Footer from '../Footer';

const Jobs = () => {
    const [searchTerm, setSearchTerm] = useState('');

    return (
        <div>
            <Nav />
            <HeaderJob />
            <Search onSearch={setSearchTerm} /> 
            <AvailableJobs searchTerm={searchTerm} /> 
            <Footer />
        </div>
    );
};

export default Jobs;