import React, { useState } from 'react';
import './Search.css';
import { FaSearch } from "react-icons/fa";

function Search({ onSearch }) { 
    const [searchTerm, setSearchTerm] = useState('');

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
        onSearch(value); // Call the passed function whenever the input changes
    };

    return (
        <div>
            <div className='top'> 
                <div className='container'>
                    <div className='search-container'>
                        <div className='search-icon'>
                            <FaSearch />
                        </div>
                        <input 
                            className='search-input' 
                            type='search' 
                            placeholder='ابحث عن وظيفتك' 
                            value={searchTerm} 
                            onChange={handleSearchChange}
                        />
                    </div>
                    <div className='jobs-text'>
                        <span>وظائف متاحة</span>
                        <span className='available-jobs-num'>200</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Search;