import React,{useState, useEffect} from 'react'
import './ApplierServices.css'
import  img1 from '../../assets/happy-businessman-holding-report.jpg'

function ApplierServices() {
    const [isResponsive, setIsResponsive] = useState(window.innerWidth <= 768);

    useEffect(() => {
      const handleResize = () => {
        setIsResponsive(window.innerWidth <= 768);
      };
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className='container'>
            <section className='applierServices'>
                
                
{isResponsive ? (
  <>

                <div className='left-applierServices'>
                    <div className='our-applierServices'>
                        <h2>الخدمات التي نقدمها للباحثين عن فرصة عمل</h2>
                        <div className='title__line'></div>
                        <p>
                        توفير فرص عمل مناسبة لكل شخص كلا حسب خبراته و مؤهلاته.                                  </p>
                    </div>
                </div>
                <div className='right-applierServices'>
                    <img src={img1} />
                </div>
  </>
) : (
  <>
<div className='right-applierServices'>
                    <img src={img1} />
                </div>
                <div className='left-applierServices'>
                    <div className='our-applierServices'>
                        <h2>الخدمات التي نقدمها للباحثين عن فرصة عمل</h2>
                        <div className='title__line'></div>
                        <p>
                        توفير فرص عمل مناسبة لكل شخص كلا حسب خبراته و مؤهلاته.                                       </p>
                    </div>
                </div>
  
  </>

)}

            </section>


        </div>


    )
}

export default ApplierServices
